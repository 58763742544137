@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
  overflow-x: hidden;
}

p {
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
  font-size: 18px;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Rubik', sans-serif;
  text-align: center;
}

h3 {
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  color: #22333B;
  font-family: 'Rubik', sans-serif;
}

.header {
  position: fixed;
  width: 100%;
  transition: all 0.3s ease;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

/* .header h3{
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
} */

.form-group {
  color: #555;
  display: flex;
  padding: 2px;
  border: 1px solid currentColor;
  border-radius: 5px;
  background-color: #f8f8f8;
}

.form-group Input:focus-visible {
  outline: none;
}

.main {
  margin-top: 80px;
}


/* custom checkbox css*/

.checkbox-block {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-block input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 4px;
}

.check-mark::after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 3px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-block:hover input~.check-mark {
  border-color: #b09975;
}

.checkbox-block input:checked~.check-mark {
  background-color: #C9B897;
  border-color: #b09975;
}

.checkbox-block input:checked~.check-mark::after {
  display: block;
}

.description-pra {
  width: 70%;
  color: #22333B;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  line-height: 23px;
  text-align: justify;
  margin: 0 !important;
}

.description-pra p {
  margin: 0 !important;
}

.category-list {
  margin: 20px 10% 20px 10%;
  width: 90%;
}

.react-checkbox-tree {
  width: 100%;
}

.rct-node-icon {
  display: none;
}

.rct-checkbox svg {
  fill: #b3a282;
}

svg.rct-icon.rct-icon-uncheck {
  width: 18px;
  height: 18px;
}

.rct-title {
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
  word-break: break-all;
}

.rct-collapse-btn {
  padding-right: 0;
}

li.rct-node,
li.rct-node.rct-node-parent {
  background-color: #22333b;
  color: #fff;
  padding: 10px;
  margin: 10px 0;
}

.react-checkbox-tree .rct-icon {
  height: 20px;
  width: 20px;
}

.aircraft li.rct-node.rct-node-leaf,
.group li.rct-node.rct-node-leaf,
.letter li.rct-node.rct-node-leaf {
  margin-left: -19px;
  max-width: 230px;
}

.France li.rct-node.rct-node-leaf,
.USA li.rct-node.rct-node-leaf,
.UK li.rct-node.rct-node-leaf {
  margin-left: -19px;
  max-width: 230px;
}

.countries li.rct-node.rct-node-leaf,
.TargetType li.rct-node.rct-node-leaf {
  margin-left: -19px;
  max-width: 230px;
}

.location li.rct-node.rct-node-leaf,
.media li.rct-node.rct-node-leaf,
.Aletters li.rct-node.rct-node-leaf {
  margin-left: -19px;
  max-width: 230px;
}

.active p {
  color: #C9B897;
}

.aircraft ol,
.letter ol,
.group ol,
.UK ol,
.USA ol,
.France ol,
.countries ol,
.TargetType ol,
.location ol {
  height: 280px;
  overflow: auto;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 6px;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #C9B897;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #C9B897;
}

/* mobile hamburger-menu css*/
.hamburger-icon {
  display: none;
  color: #C9B897;
  font-size: 20px;
  cursor: pointer;
}

.navbar-menu.menu-active {
  display: flex;
}

.navbar {
  position: absolute;
  z-index: 1;
  background: #282c34;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  left: 0;
  right: 0;
  max-width: 100%;
  padding: 5px 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.navbar::-webkit-scrollbar {
  display: none;
}

.description-container {
  max-width: 70%;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding: 20px;
}

@media (max-width:768px) {
  .main {
    margin-top: 88px;
  }
}

@media (max-width: 600px) {

  .form-group {
    margin: 0 auto;
  }

  .main {
    margin-top: 138px;
  }

  .category-list {
    margin: 20px 5% 20px 5%;
  }

  .hamburger-icon {
    display: block;
    text-align: right;
    padding: 10px;
  }

  h1 {
    font-size: 28px;
  }

  .description-container {
    max-width: 90%;
  }

  p {
    font-size: 16px;
  }
}